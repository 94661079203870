// reflectionTexts
export const reflectionTexts = [
    "What lesson took you the longest to learn?",
    "What's something you went through growing up that shaped who you are today?",
    "If you died today, what would you most regret not having told someone?",
    "What was a defining moment in your life?",
    "What's been your favorite age so far?",
    "Where do you want to be in 5 years?",
    "What are you looking for at this time in your life, relationship-wise?\nHow did you get to this point?",
    "What's something about you that others might see as a red flag?",
    "What question would you ask if you were guaranteed an answer?"
  ];
  
  // funTexts
  export const funTexts = [
    "What sounded awesome until you tried it?",
    "If you made a playlist for me, which 3 songs would be on it?",
    `You have a single-use portal gun.\n1. Where do you place your two permanent portals?\n2. How would you use them?`,
    `Pizza is now illegal. What would its street name be and what toppings would be sold the most?`,
    "What's the title of your Spotify daylist?"
  ];
  
  // aboutYouTexts
  export const aboutYouTexts = [
    "What's something you were insecure about growing up?",
    "What's your biggest pet peeve?",
    "What's something basic that you love anyway?",
    "Which of your siblings are you closest with?",
    "How do you deal with conflict?",
    "What's the most surprising thing on your bucket list?",
    "What do friends come to you for advice on most often?",
    "What's something you are insecure about now?"
  ];
  
  // togetherTexts
  export const togetherTexts = [
    "Each person writes a message to the other. Exchange only once you two have parted for the day.",
    "Each text someone you're grateful for and who you haven't reminded in while.",
    "Give each other a genuine compliment."
  ];
  
  // memoriesTexts
  export const memoriesTexts = [
    "What is a memorable lesson you learned from a family member?",
    "Tell me about a memory you have from a special place in your past?",
    "What's the story of your first kiss?",
    "Talk about your most memorable birthday.",
    "What's the best concert you've been to?",
    "As a kid, what did you want to be when you grew up?"
  ];
  
  // storytimeTexts
  export const storytimeTexts = [
    "Tell me about your favorite travel experience.",
    "What was your most awkward dating experience?",
    "Worst date story."
  ];
  
  // dealBreakersTexts
  export const dealBreakersTexts = [
    "What is something you can't tolerate in a friendship?",
    "What would make you walk out on a first date?",
    "Do you have any non-negotiables?",
    "What's the biggest ick to you?"
  ];