import React from 'react';
import './ConvoCard.css';

const ReflectionCard = ({ text }) => {
  return (
    <div className="convo-card reflection">
      <p className="ccard-text">{text}</p>
    </div>
  );
}

const FunCard = ({ text }) => {
  return (
    <div className="convo-card fun">
      <p className="ccard-text">{text}</p>
    </div>
  );
}

const AboutYou = ({ text }) => {
  return (
    <div className="convo-card aboutyou">
      <p className="ccard-text">{text}</p>
    </div>
  );
}

const TogetherCard = ({ text }) => {
  return (
    <div className="convo-card together">
      <p className="ccard-text">{text}</p>
    </div>
  );
}

const MemoriesCard = ({ text }) => {
  return (
    <div className="convo-card memories">
      <p className="ccard-text">{text}</p>
    </div>
  );
}

const StorytimeCard = ({ text }) => {
  return (
    <div className="convo-card storytime">
      <p className="ccard-text">{text}</p>
    </div>
  );
}

const DealBreakersCard = ({ text }) => {
  return (
    <div className="convo-card dealbreakers">
      <p className="ccard-text">{text}</p>
    </div>
  );
}

// General card
const ConvoCard = ({ type, text }) => {
  if (type === 'reflection') {
    return <ReflectionCard text={text} />;
  }
  else if (type === 'fun') {
    return <FunCard text={text} />;
  }
  else if (type === 'aboutyou') {
    return <AboutYou text={text} />;
  }
  else if (type === 'together') {
    return <TogetherCard text={text} />;
  }
  else if (type === 'memories') {
    return <MemoriesCard text={text} />;
  }
  else if (type === 'storytime') {
    return <StorytimeCard text={text} />;
  }
  else if (type === 'dealbreakers') {
    return <DealBreakersCard text={text} />;
  }
  else {
    return null; // Handle other types or fallback
  }
}

export default ConvoCard;
