import React from 'react';
import ReactDOM from 'react-dom';
import {
  Switch,
  Route,
  HashRouter
} from "react-router-dom";
import SimpleReactLightbox from 'simple-react-lightbox';

// Components
import NavHam from './components/NavHam';
import App from './App';
import About from './About';
import TimeWarp from "./detail-pages/TimeWarp";
import Picnic from "./detail-pages/Picnic";
import BudgetBuddy from "./detail-pages/BudgetBuddy";
import BreakingTheIce from "./detail-pages/BreakingTheIce";
import MicrosoftWarroom from "./detail-pages/MicrosoftWarroom";
import PopUpStudy from "./detail-pages/PopUpStudy";
import Footer from './components/Footer';
import FourOhFour from './FourOhFour'; // 404 pages
import CApp from './cards/CardsApp'; // Convo Cards


// CSS
import './css/Main.css';

ReactDOM.render(
  <React.StrictMode>
    <div>
      <HashRouter basename='/'>

        {/* Conditionally render NavHam only if not on cards app */}
        <Route path="/">
          {({ location }) => location.pathname !== '/start-the-convo' && <NavHam />}
        </Route>
        
        <Switch>
          <Route exactly path="/about">
            <About />
          </Route>

          <Route exactly path="/time-warp">
            <SimpleReactLightbox>
              <TimeWarp />
            </SimpleReactLightbox>
          </Route>

          <Route exactly path="/picnic">
            <SimpleReactLightbox>
              <Picnic />
            </SimpleReactLightbox>
          </Route>

          <Route exactly path="/budget-buddy">
            <SimpleReactLightbox>
              <BudgetBuddy />
            </SimpleReactLightbox>
          </Route>

          <Route exactly path="/breaking-the-ice">
            <SimpleReactLightbox>
              <BreakingTheIce />
            </SimpleReactLightbox>
          </Route>

          <Route exactly path="/microsoft-warroom">
            <SimpleReactLightbox>
              <MicrosoftWarroom />
            </SimpleReactLightbox>
          </Route>

          <Route exactly path="/video-player">
            <SimpleReactLightbox>
              <PopUpStudy />
            </SimpleReactLightbox>
          </Route>

          <Route path="/404">
            <FourOhFour />
          </Route>

          <Route exactly path="/start-the-convo">
            <CApp />
          </Route>

          <Route exactly path="/">
            <App />
          </Route>
        </Switch>

        {/* Conditionally render Footer only if not on cards app */}
        <Route path="/">
          {({ location }) => location.pathname !== '/start-the-convo' && <Footer />}
        </Route>

      </HashRouter>

    </div>
  </React.StrictMode>,
  document.getElementById('root')
);