import React from 'react';
import ConvoCard from './ConvoCard';
import { reflectionTexts, funTexts, aboutYouTexts, togetherTexts, memoriesTexts, storytimeTexts, dealBreakersTexts } from './cardData';

const CardsApp = () => {
  return (
    <div className="card-container">
      
      <div className="card-type">
        <h2>Reflection Texts</h2>
        <div className="card-columns">
          {reflectionTexts.map((text, index) => (
            <ConvoCard key={index} type="reflection" text={text} />
          ))}
        </div>
      </div>

      <div className="card-type">
        <h2>Fun Texts</h2>
        <div className="card-columns">
          {funTexts.map((text, index) => (
            <ConvoCard key={index} type="fun" text={text} />
          ))}
        </div>
      </div>

      <div className="card-type">
        <h2>About You Texts</h2>
        <div className="card-columns">
          {aboutYouTexts.map((text, index) => (
            <ConvoCard key={index} type="aboutyou" text={text} />
          ))}
        </div>
      </div>

      <div className="card-type">
        <h2>Together Texts</h2>
        <div className="card-columns">
          {togetherTexts.map((text, index) => (
            <ConvoCard key={index} type="together" text={text} />
          ))}
        </div>
      </div>

      <div className="card-type">
        <h2>Memories Texts</h2>
        <div className="card-columns">
          {memoriesTexts.map((text, index) => (
            <ConvoCard key={index} type="memories" text={text} />
          ))}
        </div>
      </div>

      <div className="card-type">
        <h2>Storytime Texts</h2>
        <div className="card-columns">
          {storytimeTexts.map((text, index) => (
            <ConvoCard key={index} type="storytime" text={text} />
          ))}
        </div>
      </div>

      <div className="card-type">
        <h2>Deal Breakers Texts</h2>
        <div className="card-columns">
          {dealBreakersTexts.map((text, index) => (
            <ConvoCard key={index} type="dealbreakers" text={text} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default CardsApp;
