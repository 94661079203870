import React, { useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import { Helmet } from 'react-helmet'; // changes <title> tags for dynamic tab titles
import { SRLWrapper } from "simple-react-lightbox";
import Carousel from 'react-bootstrap/Carousel';
import ScrollIntoView from 'react-scroll-into-view';

// Components
import DetailsHeading from '../components/DetailsHeading';

// CSS
import 'bootstrap/dist/css/bootstrap.css';
import '../css/Main.css'; // global styles
import '../css/DetailsPage.css'; // global styles

// Constant variables
import { lightboxOptions } from '../SpecificComponents/Constants'; // image lightbox styles

// Images
import SlingPlayerCover from '../assets/images/popup/SlingPlayerCover.png';
import HuluControls from '../assets/images/popup/interfaces/hulu.png';
import YTVControls from '../assets/images/popup/interfaces/ytv.png';
import FuboControls from '../assets/images/popup/interfaces/fubo.png';
import RecruitmentTeam from '../assets/images/popup/team-1.png';
import ParticipantInStudy from '../assets/images/popup/ParticipantInStudy.png';
import LiveTVCCIcons from '../assets/images/popup/LiveTVCCIcons.png';
import Remote from '../assets/images/popup/remote.png';
import PlayerControlsRemote from '../assets/images/popup/playercontrols-remote.png';



function PopUpStudy() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="PopUp">
            <Helmet>
                <title>Gabrielle LaBorwit | Reimagining the Video Player</title>
            </Helmet>

            <div className="container mt-2 mt-md-5">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-9">

                        <div className="row justify-content-center">
                            <div className="col-12">
                                <DetailsHeading title="Reimagining the Video Player" tagline="Pop up research to redesign Sling TV’s video player" image={SlingPlayerCover} alt="Sling TV video player interface with controls visible" />
                            </div>
                        </div>

                        {/* Overview */}
                        <section>
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-10 mt-3 mt-md-0">
                                    <h3 className="mb-3" id="projectoverview">OVERVIEW</h3>
                                    <h4 className="d-none d-md-block">Summary</h4>
                                    <p>
                                        Working with a team to redesign our video player, I coordinated and conducted pop up interviews in the heart of Downtown Denver. Participants were recruited from Denver’s biggest train station and taken to our nearby office’s client testing lab.
                                    </p>
                                    <p>
                                        There, they completed several tasks on different TV streaming services, usability testing the video players and thinking out loud to answer questions.
                                    </p>
                                </div>
                            
                                <div className="col-12 col-md-10">
                                    <div className="row mt-3 mt-md-5 project-overview-details">
                                        <div className="col-md-3">
                                            <h4>Timeline</h4>
                                            <ul>
                                                <li>April 2023</li>
                                                <li>1 week</li>
                                            </ul>

                                            <h4 className="mt-0 mt-md-5">Client</h4>
                                            <ul>
                                                <li>
                                                    Sling TV
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-md-3">
                                            <h4>Role</h4>
                                            <ul>
                                                <li>
                                                    UX Researcher
                                                </li>
                                                <li>
                                                    <em>
                                                        Organized, conducted, and led project; created interview guide, recruited for and ran interviews, analyzed data
                                                    </em>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-md-3">
                                            <h4 className="d-block d-md-none d-lg-block">Teammates</h4>

                                            <h4 className="d-none d-md-block d-lg-none">Team-mates</h4>

                                            <ul>
                                                <li>
                                                    Kylee Bulla
                                                </li>
                                                <li>
                                                    <em>
                                                        Helped with recruitment and set up
                                                    </em>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-md-3">
                                            <h4>Research Methods</h4>
                                            <ul>
                                                <li>User Interviews</li>
                                                <li>Usability Testing</li>
                                                <li>Think Aloud Protocol</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>


                        {/* Preparation */}
                        <section>
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-10">
                                    <h3 className="mb-3" id="prep">
                                        PREPARING
                                    </h3>
                                    <h4 id="early-start">
                                        An Early Start
                                    </h4>
                                    <p>
                                        The video player team, composed of a product manager and two designers, visited Denver for a week to collaborate on a video player redesign. As a starting point, I suggested doing discovery research around what works and doesn’t work for other streaming services’ players.
                                    </p>

                                    <h4 id="choosing-method" className="mt-5">
                                        Choosing a Method
                                    </h4>
                                </div>

                                <div className="col-12 col-md-10">
                                    <p>
                                        We needed a fast and agile method that would allow the team to work quickly, given their short stay. I decided pop-up research would be best suited, as we could recruit participants relatively easily and cheaply.
                                    </p>
                                </div>

                                <div className="col-12 col-md-10">
                                    <h4 id="recruitment" className="mt-5">
                                        Recruitment
                                    </h4>
                                    <p>
                                        Alongside my manager and another coworker, we recruited 4 participants from Denver’s bustling transport hub, Union Station. Although the original plan was to bring in 5-7 people, it was baseball Opening Day, which interfered with our recruitment success.
                                    </p>
                                </div>

                                <div className="col-12">
                                    <div className="row justify-content-center align-items-center my-4">
                                        <div className="col-12">
                                            <SRLWrapper options={lightboxOptions}>
                                                <Image className="img-zoom" src={RecruitmentTeam} width="100%" alt="Affinity diagrammed interview notes on Miro" />
                                            </SRLWrapper>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section>


                        {/* Actual Research */}
                        <section>
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-10">
                                    <h3 className="mb-3" id="study">
                                        THE STUDY
                                    </h3>
                                    <p className='mb-0'>
                                        Participants were brought to our downtown office’s testing lab, where we had a TV set up with each of the streaming services they would be testing.
                                    </p>
                                </div>

                                <div className="col-12 my-5">
                                    <Carousel className="pb-2" interval={null}>
                                        <Carousel.Item>
                                            <Image
                                                className="d-block w-100"
                                                src={HuluControls}
                                                alt="Interface 1- Video player controls visible on Hulu with Live TV"
                                                draggable={false}
                                            />
                                        </Carousel.Item>

                                        <Carousel.Item>
                                            <Image
                                                className="d-block w-100"
                                                src={YTVControls}
                                                alt="Interface 2- Video player controls visible on Youtube TV"
                                                draggable={false}
                                            />
                                        </Carousel.Item>

                                        <Carousel.Item>
                                            <Image
                                                className="d-block w-100"
                                                src={FuboControls}
                                                alt="Interface 3- Video player controls visible on Fubo TV"
                                                draggable={false}
                                            />
                                        </Carousel.Item>
                                    </Carousel>
                                </div>


                                <div className="col-12 col-md-10">
                                    <p>
                                        I started the study by showing participants images of each service’s player, with the controls showing (see above images). For all of the interfaces, they were asked what they believed each icon signified and what they expected it to do or control.
                                    </p>

                                    <p className='mb-0'>
                                        Next, participants were given a remote and asked to actually use each of the streaming services to complete different tasks. We tested:
                                        <ul>
                                            <li>
                                                Fast-forward and rewind functionality for live vs on-demand content
                                            </li>

                                            <li>
                                                How people find different shows from within the player
                                            </li>

                                            <li>
                                                Turning on/off closed captions for a basic player controls usability test
                                            </li>
                                        </ul>
                                    </p>
                                </div>

                                <div className="col-12">
                                    <div className="row justify-content-center align-items-center my-4 mb-lg-0">
                                        <div className="col-12">
                                            <SRLWrapper options={lightboxOptions}>
                                                <Image className="img-zoom" src={ParticipantInStudy} width="100%" alt="Affinity diagrammed interview notes on Miro" />
                                            </SRLWrapper>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section>

                        {/* Results */}
                        <section>
                            <div className="row justify-content-center align-items-center">
                                <div className="col-12 col-md-10">
                                    <h3 className="mb-3" id="results">
                                        RESULTS
                                    </h3>

                                    {/* Test 1: Icon Recognition */}
                                    <h4 id="findings">
                                        Test 1: Icon Recognition
                                    </h4>
                                    <p className='mb-0'>
                                        Several icons caused confusion for users within the different video players. The two that caused the most issues were the “Live TV” and “Closed Captions” icons on Fubo.
                                    </p>
                                </div>

                                <div className="col-12">
                                    <div className="row justify-content-center align-items-center my-4">
                                        <div className="col-12">
                                            <SRLWrapper options={lightboxOptions}>
                                                <Image className="img-zoom" src={LiveTVCCIcons} width="100%" alt="Live TV and Closed Captions (CC) Icons in video player controls" />
                                            </SRLWrapper>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-10">
                                    <p>
                                        Users assumed “Live TV” was an indicator that a show was airing live (not a rerun). In reality, it was a button that led to other content available to watch. To avoid similar problems, we should make sure our headings are clear and test them for understandability.
                                    </p>

                                    <p>
                                        As for “Closed Captions”, participants weren’t able to distinguish the icon from a “10 foot” distance. Since people primarily watch shows and movies on their TV, we have to keep the “10 foot” industry standard in mind, i.e. the distance people are away from the TV as they watch.
                                    </p>

                                    {/* Test 2: Using Competitor Players */}
                                    <h4 id="findings" className="mt-5">
                                        Test 2: Using Competitor Players
                                    </h4>

                                    {/* Result 1 */}
                                    <h5 className='pt-1'>People assume the down arrow will take them to player controls</h5>
                                    <p>
                                        Data showed that people expect to get to player controls by clicking the down arrow. On one streaming service, clicking the down arrow took users to a nested row of shows they could switch to. This led to confusion and users thinking they did something wrong, which is the opposite of what we want when providing a good user experience.
                                    </p>
                                </div>

                                <div className="col-12 col-md-10">
                                    <div className="col-12">
                                        <div className="row justify-content-center align-items-center my-4">
                                            <div className="col-12">
                                                <SRLWrapper options={lightboxOptions}>
                                                    <Image className="img-zoom" src={PlayerControlsRemote} width="100%" alt="Sling video player controls with Recent Channels tab open and remote to right of it" />
                                                </SRLWrapper>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-10">
                                    <p>
                                        When redesigning our interface, we need to make sure the down arrow not only goes to the player controls, but also lands on the first row with all options, not a nested one.
                                    </p>


                                    {/* Result 2 */}
                                    <h5 className='pt-4'>Participants expect to be able to find other shows to watch from the video player controls</h5>
                                    <p>
                                        People expect to be able to find other shows to watch from the video player. Moreover, they expect shows to be recommended based on what they’re currently watching or have watched in the past, and want filters to better sort through the available shows. I recommended the team includes a more content tab with filters.
                                    </p>

                                    <p className="blockquote">
                                        I like the categories under 'More to Watch' because I go to what I want to watch, not just the options they are giving me
                                    </p>


                                    {/* Result 3 */}
                                    <h5 className='pt-4'>Even for live content, people use the arrow keys to fast-forward and rewind</h5>
                                    <p>
                                        Our participants use the left and right arrow keys on the remote exclusively for fast-forward and rewind. The team was considering using the arrow keys for a different functionality, but the research provided enough evidence to keep the functionality the same.
                                    </p>
                                </div>


                                <div className="col-12">
                                    <div className="row justify-content-center align-items-center my-4">
                                        <div className="col-12">
                                            <SRLWrapper options={lightboxOptions}>
                                                <Image className="img-zoom" src={Remote} width="100%" alt="Roku remote from keypad up" />
                                            </SRLWrapper>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-10">
                                    {/* Result 4 */}
                                    <h5 className='pt-4'>Leaving player controls visible for longer can avoid frustration</h5>
                                    <p>
                                        An older participant had trouble clicking the remote fast enough before the player controls went away. She blamed herself and became frustrated, even though it’s not her fault the interface moved too fast for her.
                                    </p>
                                    
                                    <p>
                                        We can fix this by ensuring we leave player controls up for a significant amount of time before hiding them. This gives users more time to locate what they’re looking for on the screen and the remote.
                                    </p>
                                </div>
                            </div>
                        </section>

                        {/* Reflection */}
                        <section>
                            <div className="row justify-content-center align-items-center">
                                <div className="col-12 col-md-10">
                                    <h3 className="mb-3" id="reflection">
                                        REFLECTION
                                    </h3>

                                    <p>
                                        Overall, this was a great learning experience and fun project to work on. As a researcher in the post-covid era, much of our research is done online. It was an exciting change of pace to get to conduct a study like this in person.
                                    </p>

                                    <p>
                                        Additionally, the study gained attention within Sling for being the organization’s first to use pop-up methodology. Spreading awareness about agile methods like this one sparked questions from other departments on how they could utilize UX research in their own projects.
                                    </p>

                                    <p>
                                        The video player team used the insights to get a head start on ideation, and we will be conducting further research with them to continue validating (or invalidating) features they develop.
                                    </p>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className="col-3 d-none d-md-block">
                        <div className="sidecar sticky-top ml-3 ml-lg-5">
                            <ul>
                                <ScrollIntoView selector="#projectoverview">
                                    <li><span>Overview</span></li>
                                </ScrollIntoView>

                                <ScrollIntoView selector="#prep">
                                    <li><span>Preparing</span></li>
                                </ScrollIntoView>

                                <ScrollIntoView selector="#study">
                                    <li><span>The Study</span></li>
                                </ScrollIntoView>

                                <ScrollIntoView selector="#results">
                                    <li><span>Results</span></li>
                                </ScrollIntoView>

                                <ScrollIntoView selector="#reflection">
                                    <li><span>Reflection</span></li>
                                </ScrollIntoView>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default PopUpStudy;